import React from 'react'
import Layout from '../../components/Layout'
import ServiceSection from '../../components/Service/ServiceSection'

const services = () => {
    return (
        <Layout>
            <ServiceSection/>
        </Layout>
        
    )
}

export default services
